import React, { useEffect, useState } from 'react'
import { Space, Table, Popconfirm, Tooltip, Spin, Input, List, Popover } from "antd"
import { DeleteOutlined, EditOutlined, SearchOutlined, CheckCircleOutlined, ToolOutlined, ScheduleOutlined } from '@ant-design/icons';
import { GetColumnSearchProps } from '../SearchLine'
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { Link } from 'react-router-dom';
import useSize from '../Equipment/EquipmentList';


export const TaskList = ({ tasks, onEdit, onDelete, onView, loading, isMobile }) => {
    const [filteredTasks, setFilteredTasks] = useState(null)

    const [tableCount, setTableCount] = useState(null)
    const { checkRole } = useContext(AuthContext);

    const windowSize = useSize()
    useEffect(() => {
        window.dispatchEvent(new Event("resize"))
    }, [])

    useEffect(() => {
        setTableCount(tasks.length)
    }, [])

    const localTime = (date) => {
        let dateTime = new Date(date)
        return new Date(dateTime
            .setHours(dateTime.getHours() - (new Date().getTimezoneOffset() / 60)))
            .toLocaleString('ru-RU')
    }

    const columns = [
        {
            title: '#',
            key: 'num',
            width: (tasks.length).toString().length * 20,
            render: (_, r, index) => (<div style={{ textAlign: 'center' }}>{index + 1}</div >)
        },
        {
            title: 'Клиент',
            dataIndex: ['customer', 'name'],
            key: 'customer',
            width: 160,
            sorter: (a, b) => a?.customer?.name?.toLowerCase().localeCompare(b?.customer?.name?.toLowerCase()),
            sortDirections: ['ascend', 'descend'],
            ...GetColumnSearchProps(['customer', 'name'], 'клиенту'),
            // ...GetColumnSearchProps(['whole#name'], 'клиенту'),
        },
        {
            title: 'Техника',
            dataIndex: 'machine',
            width: 150,
            key: 'machine',
            sorter: (a, b) => a?.machine?.brand?.toLowerCase().localeCompare(b?.machine?.brand?.toLowerCase()),
            sortDirections: ['ascend', 'descend'],
            ...GetColumnSearchProps('whole#machine', 'машину'),
            render: (el, rec) => {
                return (el
                    ? (
                        <>
                            <div
                                // className={(rec?.pictures?.length === 0 && rec?.taskTitle === 'Монтаж' && rec?.status === 'Выполнено') ? 'notFull' : ''}
                                className={
                                    (rec?.pictures?.filter(el => el.category !== 'act').length === 0
                                        && rec?.status === 'Выполнено')
                                        ? 'notFull' : ''}

                            >{el.brand} {el.model}</div>
                            {(el.serialNumber || el.garageNumber) &&
                                <div style={{ fontSize: 12, color: '#a8a8a8' }}>
                                    {el.serialNumber}{el.garageNumber ? ` / ${el.garageNumber}` : ''}
                                </div>}
                        </>
                    )
                    : ''
                )
            },
        },
        {
            title: 'Вид задания',
            dataIndex: 'taskTitle',
            key: 'taskTitle',
            width: 120,
            ...GetColumnSearchProps('taskTitle', 'виду задания'),
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            ...GetColumnSearchProps('status', 'статусу задания'),
        },
        {
            title: 'Ответственный',
            dataIndex: 'worker',
            key: 'worker',
            ...GetColumnSearchProps('whole#worker', 'ответственному'),
            width: 160,
            sorter: (a, b) => a?.worker[0]?.lastname?.toLowerCase().localeCompare(b?.worker[0]?.lastname?.toLowerCase()),
            sortDirections: ['ascend', 'descend'],

            render: (val, i, o) => {

                return val?.length > 0
                    ? (<>{val[0]?.lastname} {val[0]?.firstname}</>)
                    : (<></>)
            }

        },
        {
            title: 'Дата вып.',
            dataIndex: 'finishDate',
            key: 'finishDate',
            width: 100,
            defaultSortOrder: 'descend',
            render: (val) => (<div>{(new Date(val)).toLocaleDateString('ru-RU')}</div>),
            sorter: (a, b) => new Date(a?.finishDate) - new Date(b?.finishDate),
            sortDirections: ['ascend', 'descend'],
        }
        ,
        {
            title: 'Вид работ',
            dataIndex: 'paymentForm',
            key: 'paymentForm',
            width: 110,
            ...GetColumnSearchProps('paymentForm', 'виду'),
        },
        {
            title: 'Приоритет',
            dataIndex: 'priority',
            key: 'priority',
            width: 110,
            ...GetColumnSearchProps('priority', 'приоритету'),
        },
        {
            title: 'Действия',
            key: 'action',
            width: 90,
            fixed: 'right',
            render: (text, record) => {
                let days = (new Date() - new Date(record?.finishDate)) / (1000 * 60 * 60 * 24)
                return <Space size={'middle'}  >
                    <SearchOutlined
                        onClick={() => onView(record)}
                    />
                    {(checkRole(['user']) && days < 14) || (checkRole(['admin', 'manager']))
                        ? <Tooltip title='Редактировать карточку' color={'geekblue'} placement="top">
                            {<EditOutlined

                                onClick={() => {
                                    onEdit(record)
                                }}
                            />}
                        </Tooltip>
                        : <EditOutlined style={{ color: 'lightgray' }} />
                    }

                    {(checkRole(['admin', 'manager'])) && <Popconfirm
                        title="Удаление записи"
                        description="Вы уверены, что хотите удалить эту запись?"
                        okText="Да"
                        cancelText="Нет"
                        onConfirm={() => onDelete(record._id)}
                    >
                        <DeleteOutlined style={{ color: 'red' }} />
                    </Popconfirm>}

                </Space >

            },
        }
    ];

    const mobileActions = (record, index) => {
        let days = (new Date() - new Date(record?.finishDate)) / (1000 * 60 * 60 * 24)
        const actions = [
            (<SearchOutlined
                onClick={() => onView(record)}
            />),

            (
                <Tooltip title='Редактировать задание' color={'geekblue'} placement="top">
                    <EditOutlined
                        onClick={() => {
                            onEdit(record)
                        }}
                    />
                </Tooltip>

            ),
            (
                checkRole(['admin', 'manager']) && <Popconfirm
                    title="Удаление записи"
                    description="Вы уверены, что хотите удалить эту запись?"
                    okText="Да"
                    cancelText="Нет"
                    onConfirm={() => onDelete(record._id)}
                >
                    <DeleteOutlined style={{ color: 'red' }} />
                </Popconfirm>
            )]
        if (checkRole(['admin', 'manager'])) { return actions }
        else {
            return [
                (<Link><SearchOutlined
                    onClick={() => onView(record)}
                /></Link>),
                (<>
                    {(days < 25)
                        ? <Tooltip title='Редактировать карточку' color={'geekblue'} placement="top">
                            {<EditOutlined style={{ color: 'red' }}

                                onClick={() => {
                                    onEdit(record)
                                }}
                            />}
                        </Tooltip>
                        : <EditOutlined style={{ color: 'lightgray' }} />
                    }</>),
            ]

        }
    }

    let mobileComponent = (
        <div>
            <Input.Search
                style={{ marginTop: '4px', marginBottom: '4px' }}
                allowClear
                placeholder='Поиск по всем полям задания'
                onSearch={(value) => {
                    let filtered = tasks.filter((item) => JSON.stringify(item).toString().toLowerCase().includes(value.toLowerCase()));
                    if (filtered) {
                        setFilteredTasks(filtered);
                    } else {
                        setFilteredTasks([])
                    }

                }}
            />

            <List
                style={{
                    marginTop: '6px',
                    height: 'calc(100vh - 220px)',
                    overflow: 'auto',
                }}
                loading={loading}
                itemLayout="horizontal"
                dataSource={filteredTasks ?? tasks}
                renderItem={(record, index) => (
                    <List.Item
                        actions={mobileActions(record, index)}

                    >
                        <List.Item.Meta
                            avatar={
                                <>

                                    <div>
                                        {record.status === 'Выполнено' && <CheckCircleOutlined style={{ color: 'green', fontSize: '20px' }} />}
                                        {(record.status === 'Выдано' || record.status === 'В работе') &&
                                            <div><ToolOutlined style={{ color: 'red', fontSize: '20px' }} /></div>}
                                        {record.status === 'Запланировано' && <div><ScheduleOutlined style={{ color: 'blue', fontSize: '20px' }} /></div>}

                                    </div>
                                    <div style={{ textAlign: 'center' }}>{index + 1}</div>
                                </>}
                            title={<span>
                                {record?.customer?.form} {record?.customer?.name}
                                {/* <span style={{ fontSize: '12px', color: 'grey', fontWeight: 300 }}>ИНН: {record?.customer?.inn}
                                </span> */}
                                <div style={{ fontSize: '12px', color: 'darkgrey', fontWeight: 400 }}>
                                    {record?.machine?.brand} {record?.machine?.model} {record?.machine?.serialNumber}
                                    {record?.machine?.garageNumber && <> / {record?.machine?.garageNumber} </>}
                                </div>
                            </span>}
                            description={<>
                                <div>{record?.taskTitle}</div>
                                <div style={{ fontSize: '12px' }}>Срок до {new Date(record.finishDate).toLocaleDateString('ru-RU')}</div>
                            </>}
                            onClick={() => onView(record)}
                        />{
                            // <>
                            //     {record.status === 'Выполнено' && <CheckCircleOutlined style={{ color: 'green', fontSize: '22px' }} />}
                            //     {record.status === 'Выдано' && <div><ToolOutlined style={{ color: 'red', fontSize: '22px' }} /></div>}
                            //     {record.status === 'Запланировано' && <div><ScheduleOutlined style={{ color: 'blue', fontSize: '22px' }} /></div>}

                            // </>
                        }
                    </List.Item>)
                }
            />
        </div >
    )

    return isMobile
        ? mobileComponent
        : (<>

            <Table
                columns={columns}
                loading={{ indicator: <div><Spin /></div>, spinning: loading }}
                dataSource={tasks}
                size="small"
                style={{ marginTop: '6px' }}
                rowKey={'_id'}
                scroll={{ x: 1000, y: +windowSize[1] - 226, }}
                pagination={false}
                onRow={(record, rowIndex) => {
                    return {
                        onDoubleClick: event => { onView(record) },
                    }
                }}
                footer={(data) => {
                    return < div style={{ textAlign: 'end' }}>Всего записей: {data?.length ?? 0}</div>
                }} // Modified from setting state
            />

        </>
        )
}