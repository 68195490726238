// PassportList.js
import React, { useRef, useState, useEffect, useContext } from 'react';
import { Space, Table, Popconfirm, Tooltip, Input, Button, List, Spin, Popover } from 'antd';
import { DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words'
import { NavLink, useNavigate, useParams, } from 'react-router-dom';
import { GetColumnSearchProps } from '../SearchLine'
import { AuthContext } from '../../context/AuthContext';
import useSize from '../Equipment/EquipmentList';


const PassportList = ({ passports, onViewDetails, onEdit, onDelete, isMobile, loading }) => {
    const inputRef = useRef(null)
    const navigate = useNavigate()
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [filteredPassports, setFilteredPassports] = useState(null)
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 16,
        total: passports?.length,
        onChange: (page) => {
            setPagination({ ...pagination, current: page });
        },
    });
    const { id } = useParams();
    const { checkRole } = useContext(AuthContext);
    const windowSize = useSize()

    useEffect(() => {
        window.dispatchEvent(new Event("resize"))
    }, [])


    const handleBlur = (selectedKeys, confirm, clearFilters, dataIndex) => {
        if (selectedKeys[0] !== '') {
            confirm();
            setSearchText(selectedKeys[0])
            setSearchedColumn(dataIndex);
        } else {
            clearFilters();
            setSearchText('');
            setSearchedColumn('');
        }
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };


    const columns = [
        {
            title: '#',
            width: (passports.length).toString().length * 20,
            style: { textAlign: 'center' },
            key: 'num',
            render: (text, record, index) => {
                const currentPage = pagination.current || 1;
                return ((currentPage - 1) * pagination.pageSize) + index + 1;
            },
        },
        {
            title: 'Дата паспорта',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 130,
            sorter: (a, b) => new Date(a?.createdAt) - new Date(b?.createdAt),
            sortDirections: ['ascend', 'descend'],
            render: (val) => (<div>{(new Date(val)).toLocaleDateString('ru-RU')}</div>),
        },

        {
            title: 'Техника',
            dataIndex: ['installation', 'machine'],
            key: 'machine',
            width: 200,
            sorter: (a, b) => a?.installation?.machine?.brand?.toLowerCase().localeCompare(b?.installation?.machine?.brand?.toLowerCase()),
            sortDirections: ['ascend', 'descend'],
            render: (val, item) => (<Popover content={equip(item.equipment)} title="Оборудование" trigger="hover">{val?.brand} {val?.model}</Popover>),
            // ...GetColumnSearchProps('userfield.model', 'модели'),
            ...GetColumnSearchProps('machineName', 'модели')
        },
        {
            title: 'Серийный №',
            dataIndex: ['installation', 'machine', 'serialNumber'],
            key: 'serialNumber',
            width: 180,
            ...GetColumnSearchProps(['installation', 'machine', 'serialNumber'], 'серийному номеру'),
        },
        {
            title: 'Клиент',
            dataIndex: ['installation', 'customer', 'name'],
            key: 'customer',
            // width: 200,
            sorter: (a, b) => a?.installation?.customer?.name?.toLowerCase().localeCompare(b?.installation?.customer?.name?.toLowerCase()),
            sortDirections: ['ascend', 'descend'],
            ...GetColumnSearchProps(['installation', 'customer', 'name'], 'клиенту'),

        },
        {
            title: 'Дата монтажа',
            dataIndex: ['installation', 'finishDate'],
            key: 'installationDate',
            width: 130,
            sorter: (a, b) => new Date(a?.installation?.finishDate) - new Date(b?.installation?.finishDate),
            sortDirections: ['ascend', 'descend'],
            render: (val) => (<div>{(new Date(val)).toLocaleDateString('ru-RU')}</div>)
        },
        {
            title: 'Дата ВЭ',
            dataIndex: 'deliveryDate',
            key: 'deliveryDate',
            defaultSortOrder: 'descend',
            width: 100,
            sorter: (a, b) => new Date(a?.deliveryDate) - new Date(b?.deliveryDate),
            render: (val) => (<div>{(new Date(val)).toLocaleDateString('ru-RU')}</div>)
        },
        {
            title: 'Действия',
            key: 'actions',
            width: 110,
            render: (text, record) => (
                <Space size={'middle'} align='center' >
                    {/* <NavLink to={`/passport/${record._id}`}> */}
                    <NavLink to={`/passport?id=${record._id}`}>

                        <SearchOutlined />
                    </NavLink>

                    {checkRole(['admin', 'manager']) && <Tooltip title='Редактировать паспорт' color={'geekblue'} placement="top">
                        <EditOutlined
                            onClick={() => {
                                onEdit(record)
                            }}
                        />
                    </Tooltip>}

                    {checkRole(['admin', 'manager']) && <Popconfirm
                        title="Удаление записи"
                        description="Вы уверены, что хотите удалить эту запись?"
                        okText="Да"
                        cancelText="Нет"
                        onConfirm={() => onDelete(record._id)}
                    >
                        <DeleteOutlined style={{ color: 'red' }} />
                    </Popconfirm>}

                </Space >
            ),
        },
    ];

    // return (
    //     <Table
    //         style={{ marginTop: '1rem' }}
    //         scroll={{ x: 550 }}
    //         dataSource={passports}
    //         pagination={pagination}
    //         columns={
    //             isMobile
    //                 ? columns
    //                 : columns}
    //         size='small' rowKey='_id' />
    // )

    const mobileActions = (record, index) => {
        const actions = [
            (
                // <NavLink to={`/passport/${record._id}`}>
                <NavLink to={`/passport?id=${record._id}`}>
                    <SearchOutlined />
                </NavLink>),
            (
                <Tooltip title='Редактировать паспорт' color={'geekblue'} placement="top">
                    <EditOutlined
                        onClick={() => {
                            onEdit(record)
                        }}
                    />
                </Tooltip>

            ),
            (<Popconfirm
                title="Удаление записи"
                description="Вы уверены, что хотите удалить эту запись?"
                okText="Да"
                cancelText="Нет"
                onConfirm={() => onDelete(record._id)}
            >
                <DeleteOutlined style={{ color: 'red' }} />
            </Popconfirm>)]
        if (checkRole(['admin', 'manager'])) { return actions }
        else {
            return [
                (
                    // <NavLink to={`/passport/${record._id}`}>
                    <NavLink to={`/passport?id=${record._id}`}>
                        <SearchOutlined />
                    </NavLink>)
            ]

        }
    }

    let mobileComponent = (
        <div>
            <Input.Search
                style={{ marginTop: '4px', marginBottom: '4px' }}
                allowClear
                placeholder='Поиск по всем полям паспорта'
                onSearch={(value) => {
                    let filtered = passports.filter((item) => {
                        const vals = value.split(' ');
                        let fil = true
                        if (typeof vals === 'object') {
                            for (let i in vals) {
                                fil = fil && JSON.stringify(item).toString().toLowerCase().includes(vals[i].toLowerCase())
                            }
                        } else {
                            fil = JSON.stringify(item).toString().toLowerCase().includes(value.toLowerCase())
                        }

                        return fil
                    })

                    // JSON.stringify(item).toString().toLowerCase().includes(value.toLowerCase()));
                    if (filtered) {
                        setFilteredPassports(filtered);
                    } else {
                        setFilteredPassports([])
                    }

                }}
            />

            <List
                style={{
                    marginTop: '6px',
                    height: 'calc(100vh - 220px)',
                    overflow: 'auto',
                }}
                loading={loading}
                itemLayout="horizontal"
                dataSource={filteredPassports ?? passports}
                renderItem={(record, index) => (
                    <List.Item
                        actions={mobileActions(record, index)}>
                        <List.Item.Meta
                            avatar={index + 1}
                            title={<div style={{ fontSize: '14px' }}>
                                {record.installation.machine.brand} {record.installation.machine.model} {record.installation.machine.serialNumber}
                            </div>}
                            description={<>
                                <div style={{ fontSize: '12px' }}>{record.installation.customer.name}</div>
                                <div style={{ fontSize: '12px' }}>Дата запуска {new Date(record.deliveryDate).toLocaleDateString('ru-RU')}</div>
                            </>
                            }
                        />
                    </List.Item>)}
            />
        </div>
    )
    const columnsEq = [
        {
            title: '#',
            dataIndex: 'key',
            width: '10px',
            key: 'key',
            render: (_, r, index) => (<div>{index + 1}</div>)
        },
        {
            title: 'Наименование',
            dataIndex: 'description',
            key: 'description',
            width: '50%',
        },
        {
            title: 'Кол-во',
            dataIndex: 'qty',
            key: 'qty',
            width: '17%',
        },
        {
            title: 'Серийный №',
            dataIndex: 'comment',
            key: 'comment',
            width: '23%',
        }
    ]
    let equip = (data) => {
        return (
            <Table
                columns={columnsEq}
                size="small"
                dataSource={data}
                loading={loading}
                pagination={false}
                rowKey={record => record._id}
            />
        )

    }

    return isMobile
        ? mobileComponent
        : (<>            <Input.Search
            style={{ width: '400px', float: 'right', marginBottom: '0.5rem' }}
            allowClear

            placeholder='Поиск по полям оборудования'
            onSearch={(value) => {
                let filtered = passports.filter((item) => {
                    const vals = value.split(' ');
                    let fil = true
                    if (typeof vals === 'object') {
                        for (let i in vals) {
                            fil = fil && JSON.stringify(item?.equipment).toString().toLowerCase().includes(vals[i].toLowerCase())
                        }
                    } else {
                        fil = JSON.stringify(item?.equipment).toString().toLowerCase().includes(value.toLowerCase())
                    }

                    return fil
                });
                if (filtered) {
                    setFilteredPassports(filtered);
                } else {
                    setFilteredPassports([])
                }

            }}
        />
            <Table
                loading={{ indicator: <div><Spin /></div>, spinning: loading }}
                style={{ marginTop: '1rem' }}
                // scroll={{ x: 550 }}
                dataSource={filteredPassports ?? passports}
                pagination={false}
                scroll={{ x: 1100, y: +windowSize[1] - 260, }}
                footer={(data) => {
                    return < div style={{ textAlign: 'end' }}>Всего записей: {data?.length ?? 0}</div>
                }} // Modified from setting state
                columns={columns}
                size='small' rowKey='_id'
                onRow={(record, rowIndex) => {
                    return {
                        onDoubleClick: event => {
                            // navigate(`/passport/${record._id}`) 
                            navigate(`/passport?id=${record._id}`)
                        }, // click row
                    };
                }}
            />
        </>

        )


};

export default PassportList;

