import React, { useContext, useEffect, useState, useCallback } from 'react';
import { ContractList, ContractForm } from '../components/Contract/Contract'; // Assuming the component is in the same directory
import { useHttp } from '../hooks/http.hook'
import { AuthContext } from '../context/AuthContext'
import { DataContext } from '../context/DataContext';
import { PlusOutlined } from '@ant-design/icons'
import { Button, Divider, Drawer, Modal, Spin, message, List, Space, Flex, Grid } from 'antd';
import { SubContract } from '../components/Contract/subContract';
import dayjs from 'dayjs';

const { useBreakpoint } = Grid;

export const ContractsPage = () => {
  const [contract, setContract] = useState(null);
  const [visible, setVisible] = useState(false);
  const [contractsList, setContractsList] = useState([]);
  const [customers, setCustomers] = useState([])
  const [machines, setMachines] = useState([])
  const [fileList, setFileList] = useState([])
  const [subFileList, setSubFileList] = useState([])
  const [pdfFileBody, setPdfFileBody] = useState(null)
  const [formload, setFormload] = useState(false)
  const [editData, setEditData] = useState(null);
  const [subVisible, setSubVisible] = useState(false)
  const [subEdit, setSubEdit] = useState(null)
  const [modalInfo, setModalInfo] = useState({})
  const [showModal, setShowModal] = useState(false)
  const { loading, request } = useHttp()
  const { token, checkRole } = useContext(AuthContext)
  const { contracts, loadContracts, addContract, updateContract, deleteContract } = useContext(DataContext)


  const isMobile = useBreakpoint().xs

  const fetchCustomers = useCallback(async () => {
    try {
      const fetched = await request('/o/api/customer', 'GET', null, {
        Authorization: `Bearer ${token}`
      })
      setCustomers(fetched)
    } catch (e) { }
  }, [token, request])

  useEffect(() => {
    fetchCustomers()
  }, [fetchCustomers])

  useEffect(() => {
    setContractsList(contracts)
  }, [contracts])

  useEffect(() => {
    loadContracts()
  }, [])

  const fetchMachines = useCallback(async () => {
    if (!(machines.length > 0)) {
      try {
        const fetched = await request('/o/api/machines', 'GET', null, {
          Authorization: `Bearer ${token}`
        })
        setMachines(fetched)
      } catch (e) { }
    }
  }, [token, request, setMachines, machines])

  // useEffect(() => {
  //   fetchMachines()
  // }, [fetchMachines])

  // useEffect(() => {
  //   // if (!contracts) { loadContracts() }
  // }, [contracts, loadContracts])



  // useEffect(() => {
  //   const loadC = async () => {
  //     let ctrs = await loadContracts()
  //     setContractsList(ctrs)
  //   }
  //   loadC()
  // }, [contracts]);

  const showDrawer = () => {
    setEditData(null);
    setVisible(true);
  };

  const showEditDrawer = (data) => {
    setEditData(data);
    setVisible(true);
  };

  const showSubEditDrawer = (data) => {
    setSubEdit(data);
    setSubVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };


  const handleDeleteContract = async (contractId) => {
    try {
      await request(`/o/api/contracts/${contractId}`, 'DELETE', null, {
        Authorization: `Bearer ${token}`
      })
      // setContract(contract.filter(contract => contract._id !== contractId));
      deleteContract(contractId)
      message.success(`Удаление выполнено!`)
    } catch (error) {
      console.error('Error deleting contract:', error);
    }
  };


  const addContractF = async (formData, onProgress) => {
    try {
      const { files, ...otherFormData } = formData; //applicableMachine

      const formDataToSend = new FormData();
      Object.entries(otherFormData).forEach(([key, value]) => {
        formDataToSend.append(key, value);
      });

      files.forEach((file, index) => {
        formDataToSend.append('files', file); //'pdf' // `file${index + 1}`
      });

      const response = await fetch('o/api/contracts', {
        method: 'POST',
        body: formDataToSend,
        headers: { Authorization: `Bearer ${token}` }
      });

      if (!response.ok) {
        throw new Error('Failed to add contract');
      }

      const jsonResponse = await response.json();
      addContract(jsonResponse)
      return jsonResponse;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const updateContractF = async (contractId, formData, onProgress) => {
    try {
      const { files, signedPdf, updSignedPdf, ...otherFormData } = formData;
      const formDataToSend = new FormData();
      Object.entries(otherFormData).forEach(([key, value]) => {
        formDataToSend.append(key, value);
      });
      updSignedPdf.forEach((el) => {
        formDataToSend.append('signedPdf', JSON.stringify(el))
      })
      files.forEach((file, index) => {
        formDataToSend.append('files', file);
      });

      setFormload(true)
      const response = await
        fetch(`o/api/contracts/${contractId}`, {
          method: 'PUT',
          body: formDataToSend,
          headers: { Authorization: `Bearer ${token}` }
        });
      setFormload(false)
      if (!response.ok) {
        if (response?.status === 413) {
          message.error('Размер одного файла не может превышать 40 Мб');
          throw new Error('Failed to update contract');
        } else {
          throw new Error('Failed to update contract');
        }


      }
      const jsonResponse = await response.json();

      updateContract(jsonResponse)
      return jsonResponse;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const onProgress = (event) => {
    // Update progress UI here
    console.log('progress', event)
  };
  ///======

  const handleContractSubmit = async (formData) => {
    let contractRecord
    try {
      if (editData?._id) {
        // console.log('formData', formData)
        contractRecord = await updateContractF(editData._id, formData, onProgress); // Call your API function to update contract
        message.success('Договор успешно обновлен');
      } else {
        contractRecord = await addContractF(formData, onProgress); // Call your API function to add new contract
        message.success('Новый договор записан');
      }
      setEditData({
        ...contractRecord,
        customer: contractRecord.customer._id,
        date: dayjs(contractRecord.date),
        startDate: dayjs(contractRecord.startDate),
        endDate: dayjs(contractRecord.endDate),
      })
      // fetchContracts();
    } catch (error) {
      console.log(error)
      if (error?.response?.status === 413) {
        message.error('Размер одного файла не может превышать 40 Мб');
      } else {
        // throw new Error(error.message);
        console.error('Error submitting contract:', error);
        // Handle error
        message.error('Ошибка при записи догвора');
      }

    }
  };


  const handleSubContractSubmit = async (formData) => {
    let contractRecord
    let parentContract = { ...subEdit.parentContract }

    try {
      if (subEdit?._id) {
        contractRecord = await updateContractF(subEdit._id, formData, onProgress);
        message.success('Дополнительное соглашение успешно обновлено');
      } else {
        contractRecord = await addContractF(formData, onProgress);
        message.success('Добавлено новое дополнительное соглашение');
      }

      setSubEdit({
        ...contractRecord,
        customer: contractRecord.customer._id,
        date: dayjs(contractRecord.date),
        startDate: dayjs(contractRecord.startDate),
        endDate: dayjs(contractRecord?.endDate),
        parentContract
        // {
        //   // _id: parent._id,
        //   // date: parent.date,
        //   // title: parent.title,
        //   customer: {
        //     name: contractRecord.customer.name,
        //     inn: contractRecord.customer.inn,
        //     _id: contractRecord.customer._id
        //   },
        // }
      })

      // setSubVisible(false)
    } catch (error) {
      console.log('Error submitting contract:', error);
    }
  };



  // const getPdfFromServer = async (path) => {
  //   try {
  //     const response = await fetch(
  //       `o/api/documents/${path}`, {
  //       method: 'GET',
  //       headers: {
  //         'Authorization': `Bearer ${token}`
  //       },
  //     }
  //     )
  //     if (!response.ok) {
  //       throw new Error('Failed to load PDF')
  //     }
  //     const blob = await response.blob()
  //     const pdfUrl = URL.createObjectURL(blob)

  //     setPdfFileBody(pdfUrl);
  //   } catch (error) {
  //     console.error('Error downloading pdf:', error);
  //   }
  // }


  const onModalView = (item) => {
    let files = item?.signedPdf?.map(el => ({
      title: el?.filename,
      path: el?.filePath,
      mimetype: el?.mimetype,
    }))
    let modal = {
      title: `${item.title} от ${(new Date(item.date)).toLocaleDateString('ru-RU')}г.`,
      info: (
        <>

          <div>Клиент: <span style={{ fontWeight: 500 }}>{item.customer.name}</span></div>
          <div>ИНН: <span style={{ fontWeight: 500 }}>{item.customer.inn}</span></div>
          <div>Адрес: <span style={{ fontWeight: 500 }}>{item.customer.address}</span></div>
          <div>Срок действия: <span style={{ fontWeight: 500 }}>
            c {item?.startDate ? (new Date(item.startDate)).toLocaleDateString('ru-RU') : '...'} по {item?.endDate ? (new Date(item.endDate)).toLocaleDateString('ru-RU') : '...'}
          </span></div>
          <Divider />
          <div style={{ fontWeight: 500, marginBottom: '0.5rem' }}>Прикрепленные документы:</div>
          {files.length > 0 ?
            <List
              size="small"
              bordered
              style={{ marginBottom: '1rem' }}
              dataSource={files}
              renderItem={(item, index) => (

                <List.Item style={{ width: '100%', display: 'flex' }}>
                  {index + 1}. {item.title}

                  <a key="view_file" onClick={() => console.log('view file', item?.path)}                  >Смотреть</a>

                </List.Item>
              )}

            >
            </List> : <></>}
          <div style={{ fontWeight: 500 }}>Примечания:</div>
          <div>{item?.comment}</div>
        </>
      ),
    }

    setModalInfo(modal)
    setShowModal(true)
  }

  const hideModal = () => {
    setShowModal(false)
  }
  const setSubContractParent = (item) => {
    setSubEdit(item)

  }

  // if (loading) {
  //   return <Loader />
  // }

  return (
    <div>
      <Flex wrap="wrap" gap="small" align='center' justify='space-between' >
        <Space align='baseline' size={'middle'} >
          <h2 style={{ marginBottom: 0, marginTop: 0 }}>Договоры</h2>
          {checkRole(['admin', 'manager', 'legal']) && <Button type="default"
            onClick={showDrawer}
            shape={isMobile ? 'circle' : ''}
            icon={<PlusOutlined />}
            style={{ borderRadius: '20px' }}
          >{isMobile ? '' : 'ДОБАВИТЬ'}</Button>}
        </Space>
      </Flex>
      <ContractList loading={loading} contractsList={contractsList} onDelete={handleDeleteContract} onEdit={showEditDrawer} onSubEdit={showSubEditDrawer} setSubVisible={setSubVisible} setSubContract={setSubContractParent} onView={onModalView} />
      <Drawer
        title={editData ? `Редактирование` : 'Новый договор'}
        width={720}
        placement="right"
        onClose={onClose}
        open={visible}
        destroyOnClose={true}
      >
        <Spin spinning={formload}>
          <ContractForm customers={customers} machines={machines} onFinish={handleContractSubmit} fileList={fileList} onClose={onClose} setFileList={setFileList} initialValues={editData} />
        </Spin>
      </Drawer>
      <Drawer
        name={'subContract'}
        title={subEdit ? `Редактирование соглашения` : 'Новое соглашение'}
        width={720}
        placement='right'
        destroyOnClose={true}
        onClose={() => { setSubVisible(false) }}
        open={subVisible}
      >
        <SubContract
          initialValues={subEdit}
          onFinish={handleSubContractSubmit}
          machines={machines}
          fileList={subFileList}
          setFileList={setSubFileList}
          onClose={() => { setSubVisible(false) }}
        />
      </Drawer>
      <Modal
        title={modalInfo.title}
        centered
        open={showModal}
        onOk={hideModal}
        onCancel={hideModal}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        {modalInfo.info}
      </Modal>


    </div>
  );
};

