import React from 'react';
import { Form, Input, Button, Select } from 'antd';
// import { updateUserProfile } from '../../../../controller/userController';


const { Option } = Select;

const UserForm = ({ user, onCloseDrawer, createNewUser, updateUserProfile }) => {

    const onFinish = (values) => {
        // Handle form submission
        handleFormSubmit(values)
        onCloseDrawer();
    };

    const handleFormSubmit = (values) => {
        if (user?._id) {
            updateUserProfile(user._id, values);
        } else {
            createNewUser(values);
        }
    }

    return (
        <Form layout="vertical" onFinish={onFinish} initialValues={user}>
            <Form.Item name="email" label="Email" rules={[{ required: true, type: 'email' }]}>
                <Input />
            </Form.Item>
            <Form.Item name="firstname" label="Имя" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item name="lastname" label="Фамилия" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            {!user?.role?.includes('customer') ?
                <Form.Item name="branch" label="Подразделение" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                :
                <Form.Item name="customer" label="Клиент" rules={[{ required: true }]}>
                    <Input disabled />
                </Form.Item>
            }

            <Form.Item name="role" label="Роли" rules={[{ required: true }]}>
                <Select
                    mode="multiple"
                    placeholder="Укажите роли"
                    // onChange={(e) => console.log(e)}
                    disabled={user?.role?.includes('customer')}
                >
                    <Option value="admin">Администратор</Option>
                    <Option value="manager">Менеджер</Option>
                    <Option value="legal">Юрист</Option>
                    <Option value="user">Пользователь</Option>
                    <Option disabled={!user?.role?.includes('customer')} value="customer">Клиент</Option>
                </Select>
            </Form.Item>
            <Form.Item name={"status"} label="Статус">
                <Select >
                    <Option value="active" >Активный</Option>
                    <Option value="locked">Заблокирован</Option>
                    <Option value="registration" disabled>Регистрация</Option>
                </Select>
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    {user ? "Записать" : "Направить приглашение"}
                </Button>
            </Form.Item>
        </Form>
    );
};

export default UserForm;
