import React, { useState, useContext, useEffect } from 'react';
import { Table, Input, List, Switch, Flex } from 'antd';
import { EditOutlined, DeleteOutlined, LockOutlined, CheckCircleOutlined, CloseCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip, Space, Tag, Spin } from 'antd';
import { Popconfirm } from 'antd';
import { GetColumnSearchProps } from '../SearchLine'

import { AuthContext } from '../../context/AuthContext';
import { Telegram } from '../Telegram';
import useSize from '../Equipment/EquipmentList';


const UserList = ({ onEditUser, onShowUser, isMobile, onDeleteUser, onLockUser, data, loading, client, setClient }) => {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [filteredUsers, setFilteredUsers] = useState(null);
    const { checkRole } = useContext(AuthContext)

    useEffect(() => {
        window.dispatchEvent(new Event("resize"))
    }, [])

    const windowSize = useSize()

    const colors = {
        admin: 'gold',
        user: 'geekblue',
        manager: 'green',
    }
    const statuses = {
        active: {
            color: 'green',
            text: 'Активный',
            icon: <CheckCircleOutlined />,
        },
        locked: {
            color: 'red',
            text: 'Заблокирован',
            icon: <CloseCircleOutlined />,

        },
        registration: {
            color: 'blue',
            text: 'Регистрация',
            icon: <QuestionCircleOutlined />,

        }
    }
    const columns = [
        {
            title: '#',
            key: 'num',
            width: (data.length).toString().length * 20,
            render: (_, r, index) => (<div>{index + 1}</div>)
        },
        {
            title: 'E-mail', dataIndex: 'email', key: 'email',
            width: 230,
            ...GetColumnSearchProps('email', 'email'),
            render: (_, r) => (
                <Space align='center'>

                    <>{r.email}</>
                    {r.tgchat && <Telegram style={{ height: 20 }} />}
                </Space>)
        },
        {
            title: 'Пользователь', dataIndex: 'lastname', key: 'lastname',
            ...GetColumnSearchProps('lastname', 'фамилии'),
            width: 130,
            render: (text, record) => {
                return (<span> {record.lastname} {record.firstname[0]}. </span>)
            }
        },
        {
            title: 'Подразделение', dataIndex: 'branch', key: 'branch',
            width: 150,
            ...GetColumnSearchProps('branch', 'подразделению'),
        },
        {
            title: 'Роль', dataIndex: 'role', key: 'role', width: 100,
            ...GetColumnSearchProps('role', 'роли'),
            render: (text, record) => (
                <span>
                    {record.role.map((role, index) => (
                        <Tag key={index} color={colors[role]}> {role} </Tag>
                    ))}
                </span>

            )
        },
        {
            title: 'Статус', dataIndex: 'status', key: 'status', width: 110,
            ...GetColumnSearchProps('status', 'статусу'),
            render: (text, record) => (
                <span>

                    <Tag key={record._id} color={statuses[record.status]?.color} icon={statuses[record.status]?.icon}>
                        {statuses[record.status]?.text}
                    </Tag>

                </span>

            )
        },
        {
            title: 'Вход',
            dataIndex: 'lastlogin',
            key: 'lastlogin',
            width: '145px',
            sorter: (a, b) => new Date(a?.lastlogin ?? 0) - new Date(b?.lastlogin ?? 0),
            sortDirections: ['ascend', 'descend'],
            render: (text, record) => record.lastlogin
                ? <div style={{ fontSize: '12px', textAlign: 'left' }}>{(new Date(record.lastlogin).toLocaleString())}</div>
                : '-',
        },
        {
            title: 'Действия',
            key: 'action',
            width: 130,

            render: (text, record) => (
                <Space size={'middle'}  >

                    {/* <SearchOutlined
                        onClick={() => onShowUser(record)}
                    /> */}
                    <Tooltip
                        placement="topRight"
                        title='Редактировать карточку' color={'geekblue'} >
                        <EditOutlined
                            onClick={() => {
                                onEditUser(record)
                            }}
                        />
                    </Tooltip>

                    <Popconfirm
                        title="Удаление записи"
                        description="Вы уверены, что хотите удалить эту запись?"
                        okText="Да"
                        cancelText="Нет"
                        placement="topRight"
                        onConfirm={() => onDeleteUser(record._id)}
                    >
                        <DeleteOutlined style={{ color: 'red' }} />
                    </Popconfirm>
                    <Popconfirm
                        title="Блокировка пользователя"
                        description={`Вы уверены, что хотите ${record.status !== 'locked' ? 'заблокировать' : 'разблокировать'} эту учетную запись?`}
                        okText="Да"
                        cancelText="Нет"
                        placement="topRight"
                        onConfirm={() => onLockUser(record)}
                    >
                        {record.status !== 'locked' ? <a>Блокировать</a> : <a>Разблокировать</a>}
                    </Popconfirm>
                </Space >
            ),
        },
    ];

    const mobileActions = (record, index) => {
        const actions = [

            (
                <Tooltip title='Редактировать пользователя' color={'geekblue'} placement="top">
                    <EditOutlined
                        onClick={() => {
                            onEditUser(record)
                        }}
                    />
                </Tooltip>

            ),
            (
                checkRole(['admin']) && <Popconfirm
                    title="Удаление записи"
                    description="Вы уверены, что хотите удалить эту запись?"
                    okText="Да"
                    cancelText="Нет"
                    onConfirm={() => onDeleteUser(record._id)}
                >
                    <DeleteOutlined style={{ color: 'red' }} />
                </Popconfirm>
            ),
            <Popconfirm
                title="Блокировка записи"
                description="Вы уверены, что хотите заблокировать пользователя?"
                okText="Да"
                cancelText="Нет"
                onConfirm={() => onLockUser(record)}
            >
                <LockOutlined style={{ color: 'blue' }} />
            </Popconfirm>
        ]
        if (checkRole(['admin'])) { return actions }
        else {
            return [

                (
                    <Tooltip title='Редактировать пользователя' color={'geekblue'} placement="top">
                        <EditOutlined style={{ color: '#ff5e00' }}
                            onClick={() => {
                                onEditUser(record)
                            }}
                        />
                    </Tooltip>

                ),
            ]

        }
    }

    let mobileComponent = (
        <div>
            <Input.Search
                style={{ marginTop: '1rem' }}
                allowClear
                placeholder='Поиск по всем полям записи пользователя'
                onSearch={(value) => {
                    let filtered = data.filter((item) => JSON.stringify(item).toString().toLowerCase().includes(value.toLowerCase()));
                    if (filtered) {
                        setFilteredUsers(filtered);
                    } else {
                        setFilteredUsers([])
                    }

                }}
            />
            <Flex wrap="wrap" gap="small" align='center' justify='space-between' >
                <p>Всего записей: {filteredUsers?.length ?? data?.length}</p>
                <Switch checkedChildren="Сотрудники" unCheckedChildren="Клиенты" checked={client}
                    onChange={() => setClient(pre => !pre)} />
            </Flex>
            <List
                style={{
                    marginTop: '6px',
                    height: 'calc(100vh - 230px)',
                    overflow: 'auto',
                    minWidth: 600,
                }}
                loading={loading}
                itemLayout="horizontal"
                dataSource={filteredUsers ?? data}
                renderItem={(record, index) => (
                    <List.Item
                        actions={mobileActions(record, index)}
                    >
                        <List.Item.Meta
                            avatar={index + 1}
                            title={<span style={{ fontWeight: 400, fontSize: '13px' }}>
                                {record.role.map((role, index) => (
                                    <Tag key={index} color={colors[role]}> {role} </Tag>
                                ))}
                                {record?.email}&nbsp;

                            </span>}
                            description={<Space direction='vertical'>
                                <div>{record?.lastname} {record?.firstname}, {record?.branch}
                                </div>
                                <Tag key={record._id} color={statuses[record.status].color} icon={statuses[record.status].icon}>
                                    {statuses[record.status].text}
                                </Tag>
                                <span>Посл.вход: {record.lastlogin ? new Date(record.lastlogin).toLocaleString() : '-'}</span>
                            </Space>}

                        />
                    </List.Item>)
                }
            />
        </div >
    )

    return isMobile
        ? mobileComponent
        : (<>

            <Table
                loading={{ indicator: <div><Spin /></div>, spinning: loading }}
                style={{ marginTop: '1rem' }}
                dataSource={data}
                columns={columns}
                size='small'
                rowKey={'_id'}
                pagination={false}
                // scroll={{ x: 550 }}
                scroll={{ x: 600, y: +windowSize[1] - 220, }}
                footer={(data) => {
                    return < div style={{ textAlign: 'end' }}>Всего записей: {data?.length ?? 0}</div>
                }} // Modified from setting state

            /></>)
};

export default UserList;
