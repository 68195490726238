import React, { useState, useEffect, useContext } from 'react';
import { Form, Select, Button, message, Descriptions, DatePicker, Divider, Input, Table, Flex, Space, Modal, InputNumber, AutoComplete } from 'antd';
import { AuthContext } from '../../context/AuthContext';
import { useForm } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { TaskForm } from '../../components/Task/TaskForm';
import { useHttp } from '../../hooks/http.hook'
import { TaskDescription } from '../Task/taskDescription';
import { isDate } from 'lodash';

const { Option } = Select;

const PassportForm = ({ editPassport, onSubmit }) => {
    const [changes, setChanges] = useState({});
    const [warr, setWarr] = useState('');
    const [tasks, setTasks] = useState([]);
    const [selectedTaskId, setSelectedTaskId] = useState(null);
    const [taskDetails, setTaskDetails] = useState(null);
    const [edit, setEdit] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { request } = useHttp()
    const { token, checkRole } = useContext(AuthContext)


    const [passportForm] = useForm()



    useEffect(() => {
        const fetchTasks = async () => {
            try {
                const response = await request('o/api/taskinstall', 'GET', null, {
                    Authorization: `Bearer ${token}`
                });
                setTasks(response);
            } catch (error) {
                console.error('Error fetching tasks:', error);
            }
        };
        fetchTasks();
        if (editPassport?._id) {
            setSelectedTaskId(editPassport._id)
            setTaskDetails(editPassport.installation)
            editPassport.task = editPassport?.installation?._id
            passportForm.setFieldsValue(editPassport)
            setWarr(editPassport.warranty)
        }
    }, []);

    const onCloseModal = () => {
        setIsModalVisible(false);
    }

    const openTaskModal = () => {
        setIsModalVisible(true);
    }

    const setWarranty = (e) => {
        setWarr(e)
        if (e === 'special') {
            // passportForm.setFieldValue('warrantyFinishDate', null)
        } else {
            let date = new Date(passportForm.getFieldValue('deliveryDate'))
            let finishDate = dayjs((new Date((date.setFullYear(date.getFullYear() + 1)) - 1000 * 60 * 60 * 24)).setHours(0, 0, 0, 0))
            passportForm.setFieldValue('warrantyFinishDate', finishDate)
            passportForm.setFieldValue('maintenanceRule', 2000)

        }
    }

    const handleTaskSelect = async (taskId) => {
        setSelectedTaskId(taskId);
        if (taskId) {
            try {
                const selectedTask = tasks.find(task => task._id === taskId);
                if (selectedTask) {
                    setTaskDetails(selectedTask);
                } else {
                    console.error('Selected task not found');
                    message.error('Selected task not found');
                }
            } catch (error) {
                console.error('Error fetching task details:', error);
                message.error('Failed to fetch task details');
            }
        } else {
            setTaskDetails(null);
        }


    };


    const handleFormSubmit = (values) => {
        if (editPassport._id) {
            onSubmit({ ...values, record: editPassport })
        } else {
            onSubmit({ record: taskDetails, ...values })
        }
    };

    const columns = [
        {
            title: '#',
            dataIndex: 'key',
            key: 'key',
            width: '4%',
            render: (_, r, index) => (<div>{index + 1}</div>)
        },
        {
            title: 'Наименование',
            dataIndex: 'description',
            key: 'description',
            width: '50%',
        },
        {
            title: 'Кол-во',
            dataIndex: 'qty',
            key: 'qty',
            width: '17%',
        },
        {
            title: 'Серийный №',
            dataIndex: 'comment',
            key: 'comment',
            width: '23%',
        }
    ]

    const warrantyOptions = [
        { value: '12_2000', label: '12 месяцев, 2000 моточасов' },
        { value: 'special', label: 'Специальная гарантия' },
    ]

    const mntcPeriod = [500, 1000, 2000].map(el => ({ value: el, label: el }))

    const setMaintenanceDate = (e => {
        if (e?.$isDayjsObject) {
            const nextMaintenanceDate = (new Date(dayjs(e)).getTime() + 183 * (24 * 60 * 60 * 1000))
            passportForm.setFieldValue('nextMaintenanceDate', dayjs(nextMaintenanceDate))
            setWarrantyFinishDate()
            return
        } else {
            const delivery = passportForm.getFieldValue('deliveryDate')
            let period = 183 //90 days
            if (+e === 500) { period = 46 }
            else if (+e === 1000) { period = 92 }


            const nextMaintenanceDate = (new Date(delivery).getTime() + period * (24 * 60 * 60 * 1000))
            passportForm.setFieldValue('nextMaintenanceDate', dayjs(nextMaintenanceDate))
            if (+e !== 2000) {
                setWarr('')
                passportForm.setFieldValue('warranty', 'special')
            }
        }


    })

    const setWarrantyFinishDate = () => {
        let date = new Date(passportForm.getFieldValue('deliveryDate'))
        let finishDate = dayjs((new Date((date.setFullYear(date.getFullYear() + 1)) - 1000 * 60 * 60 * 24)).setHours(0, 0, 0, 0))
        passportForm.setFieldValue('warrantyFinishDate', finishDate)
        // console.log(finishDate)
    }

    return (
        <>
            <Form
                form={passportForm}
                layout="horizontal" onFinish={handleFormSubmit} initialValues={edit}
            >
                {!editPassport?._id ?
                    <Form.Item label="Задание" name="task"
                        rules={[{ required: true, message: 'Выберите завершенное задание монтажа' }]}>
                        <Select
                            placeholder="Выберите завершенное задание" onChange={handleTaskSelect}>
                            {tasks.map((task) => (
                                (task.machine?.brand && task.machine?.model && task.machine?.serialNumber) &&
                                <Option key={task._id} value={task._id}>{`${task.machine?.brand ?? ''} ${task.machine?.model ?? ''} ${task.machine?.serialNumber ?? ''} - ${task.customer.name}`}</Option>
                            ))}
                        </Select>

                    </Form.Item>
                    : <> </>}

                {taskDetails && (
                    <>
                        <Descriptions column={1}  >
                            <Descriptions.Item label="Техника" style={{ paddingBottom: '4px' }}>
                                {taskDetails.machine?.brand} {taskDetails.machine?.model}
                            </Descriptions.Item>
                            <Descriptions.Item label="Серийный номер / Гаражный номер" style={{ paddingBottom: '4px' }}>
                                {taskDetails.machine?.serialNumber} / {taskDetails.machine?.garageNumber}
                            </Descriptions.Item>
                            <Descriptions.Item label="Наработка, м.ч." style={{ paddingBottom: '4px' }}>
                                {taskDetails.lastOperationHours}
                            </Descriptions.Item>
                            <Descriptions.Item label="Клиент" style={{ paddingBottom: '4px' }}>
                                {taskDetails.customer.name} ИНН: {taskDetails.customer.inn}
                            </Descriptions.Item>
                            <Descriptions.Item label="Договор" style={{ paddingBottom: '4px' }}>
                                {taskDetails?.contract?._id && (
                                    <>
                                        {taskDetails?.contract?.title} от {new Date(taskDetails?.contract?.date).toLocaleDateString('ru-RU')}
                                    </>)}
                            </Descriptions.Item>
                            <Descriptions.Item label="Дата монтажа" style={{ paddingBottom: '4px' }}>
                                {new Date(taskDetails.finishDate).toLocaleDateString('ru-RU')}
                            </Descriptions.Item>
                            <Descriptions.Item label="Место монтажа" style={{ paddingBottom: '4px' }}>
                                {taskDetails.jobSite}
                            </Descriptions.Item>
                            <Descriptions.Item label="Акт выполненных работ №" style={{ paddingBottom: '4px' }}>
                                {taskDetails.documentNumber}
                            </Descriptions.Item>
                            <Descriptions.Item label="Подписант" style={{ paddingBottom: '4px' }}>
                                {taskDetails.signer}
                            </Descriptions.Item>
                            <Descriptions.Item label="Исполнители" style={{ paddingBottom: '4px' }}>
                                {taskDetails.worker.map(w => `${w.lastname} ${w.firstname}`).join(', ')}
                            </Descriptions.Item>

                            {/* Add other fields here */}
                        </Descriptions>

                        {taskDetails?.equipment.length > 0 && (
                            <>
                                <label>Оборудование</label>
                                <Table dataSource={taskDetails?.equipment} columns={columns} pagination={false}
                                    rowKey={'_id'}
                                    size='small'
                                    style={{ marginBottom: '10px' }} />
                            </>
                        )}
                        {!taskDetails?.equipment.length > 0 && (
                            <>
                                <h3 style={{ color: 'red' }}>Оборудование не установлено</h3>
                            </>
                        )}

                        <Form.Item
                            label="Дата ввода в эксплуатацию"
                            name="deliveryDate"

                            style={{ alignItems: 'center' }}
                            rules={[{ required: true, message: 'Введите дату' }]}
                        >
                            <DatePicker onChange={setMaintenanceDate} format="DD.MM.YYYY" style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item label="Гарантия"
                            name="warranty"
                            style={{ alignItems: 'baseline' }}
                            defaultValue="12_2000"

                            rules={[{ required: true, message: 'Выберите условие гарантии' }]}
                        >
                            <Select
                                onChange={setWarranty}
                                options={warrantyOptions}
                            />
                        </Form.Item>

                        <Form.Item
                            name={'warrantyFinishDate'}
                            label="Дата окончания гарантии"
                            rules={[{ required: true, message: 'Введите дату окончания гарантии' }]}

                        >
                            <DatePicker
                                disabled={warr === '12_2000'}
                                format="DD.MM.YYYY"
                                onChange={setWarrantyFinishDate}
                                style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item label="Межсервисный интервал, м.ч." name="maintenanceRule">
                            <Select
                                onChange={setMaintenanceDate}
                                options={mntcPeriod}
                                style={{ width: '100%' }}
                            />
                            {/* <InputNumber min={500} style={{ width: '100%' }} /> */}
                        </Form.Item>
                        <Form.Item label="Комментарий" name="comment" style={{ alignItems: 'baseline' }}>
                            <Input.TextArea rows={3} />
                        </Form.Item>
                        <Form.Item
                            label="Дата следующего ТО"
                            name="nextMaintenanceDate"

                            style={{ alignItems: 'center' }}
                            rules={[{ required: true, message: 'Введите дату' }]}
                        >
                            <DatePicker format="DD.MM.YYYY" style={{ width: '100%' }} />
                        </Form.Item>

                    </>
                )}
                <Flex wrap="wrap" gap="small" align='center' justify='space-between' >
                    <Space align='baseline' size={'middle'} >
                        <Form.Item>
                            {taskDetails?.equipment.length > 0 && <Button type="primary" htmlType="submit" >Записать</Button>}
                        </Form.Item>
                    </Space>
                    <Space align='baseline' size={'middle'} >
                        <Form.Item>
                            {(checkRole(['admin']) && taskDetails?._id) && <Button type="primary" onClick={openTaskModal}>Перейти к заданию</Button>}
                        </Form.Item>
                    </Space>
                </Flex>


            </Form>
            <Modal
                okText={"Закрыть"}
                centered
                width='80vw'
                // afterClose={() => setDescription(null)}
                open={isModalVisible}
                onOk={onCloseModal} onCancel={onCloseModal}
                footer={(_, { OkBtn }) => (<OkBtn />)}
            >

                <TaskDescription values={taskDetails} />
            </Modal>
        </>
    );
};

export default PassportForm;



// import React, { useEffect } from 'react'
// import { Form, Input, DatePicker, Select } from 'antd';
// import { useForm } from 'antd/es/form/Form';

// const { Option } = Select;


// const PassportForm = ({ onFinish, initialValues }) => {
//     const [form] = useForm()
//     useEffect(() => {
//         form.setFieldsValue(initialValues);
//     }, [initialValues])

//     return (
//         <Form
//             form={form}
//             initialValues={initialValues}
//             onFinish={onFinish}
//             layout="vertical"

//         >
//             {/* <Form.Item name="title" label="Title" >
//                 <Input />
//             </Form.Item> */}
//             <Form.Item label="Модель техники" name="machineModel" >
//                 <Input />
//             </Form.Item>
//             <Form.Item label="Серийный номер" name="serialNumber" >
//                 <Input />
//             </Form.Item>
//             <Form.Item label="Дата монтажа" name="installationDate" >
//                 <DatePicker
//                     format={'DD.MM.YYYY'}
//                     popupStyle={{ maxWidth: '200px' }}
//                 />
//             </Form.Item>
//             <Form.Item label="Дата запуска" name="deliveryDate" >
//                 <DatePicker
//                     format={'DD.MM.YYYY'}
//                     popupStyle={{ maxWidth: '200px' }}
//                 />
//             </Form.Item>
//             <Form.Item label="Задание на монтаж" name="deliveryTask" >
//                 <Input />
//             </Form.Item>
//             {/* Add other form fields for machine model, serial number, installation date, etc. */}
//             {/* Select component for workers who installed the system */}
//             {/* Logic to calculate and display warranty condition */}
//         </Form>
//     );
// };

// export default PassportForm;