import { useEffect, useState, useContext } from "react"
import { useHttp } from './http.hook'
import { AuthContext } from "../context/AuthContext"
// import { AuthContext } from "../context/AuthContext";


export const useLoadData = (token, client) => {
    const [contracts, setContracts] = useState([])
    const [branches, setBranches] = useState([])
    const [customers, setCustomers] = useState([])
    const [firstload, setFirstload] = useState(false)
    const [machines, setMachines] = useState([])
    const [settings, setSettings] = useState([])
    const [isAuth, setIsAuth] = useState(!!token)
    const { request } = useHttp()
    // const { token } = useContext(AuthContext)

    useEffect(() => {
        setIsAuth(!!token)
    }, [token])

    useEffect(() => {
        setIsAuth(!!token)
        if (isAuth && !client) {
            setFirstload(true)
            fetchCustomers()
            fetchContracts()
            fetchUsers()
            fetchMachines()
            fetchSettings()
            setFirstload(false)
        }

    }, [isAuth])

    const fetchCustomers = async () => {
        try {
            const response = await request('o/api/customer', 'GET', null, {
                Authorization: `Bearer ${token}`
            });
            setCustomers(response);
        } catch (error) {
            console.error('Error fetching customers:', error);
        }
    };

    const fetchUsers = async () => {
        try {
            const response = await request('o/api/allbranches', 'GET', null, {
                Authorization: `Bearer ${token}`
            });
            setBranches(response);
        } catch (error) {
            console.error('Error fetching users:', error);
        }

    }

    const fetchSettings = async () => {
        try {
            const response = await request('o/api/settings', 'GET', null, {
                Authorization: `Bearer ${token}`
            });
            if (response) {
                setSettings(response)

            }
        } catch (error) {
            console.error('Error fetching users:', error);
        }

    }
    const fetchMachines = async () => {
        try {
            const response = await request('/o/api/machines', 'GET', null, {
                Authorization: `Bearer ${token}`
            })
            setMachines(response)

        } catch (error) {
            console.error('Error fetching Machines:', error);
        }
    }

    const fetchContracts = async () => {
        try {
            const response = await request('o/api/contracts', 'GET', null, {
                Authorization: `Bearer ${token}`
            });
            setContracts(response);
        } catch (error) {
            console.error('Error fetching contracts:', error);
        }

    }

    const updateCustomer = (updatedCustomer) => {
        setCustomers((prevCustomers) =>
            prevCustomers.map((customer) =>
                customer._id === updatedCustomer._id ? updatedCustomer : customer
            )
        );
    };

    const changeSettings = (updatedSettings) => {
        // console.log('updatedSettings', updatedSettings)
        setSettings(updatedSettings);
    };

    const addCustomer = (newCustomer) => {
        setCustomers((prevCustomers) => [...prevCustomers, newCustomer]);
    };

    const deleteCustomer = (customerId) => {
        setCustomers((prevCustomers) =>
            prevCustomers.filter((customer) => customer._id !== customerId)
        );
    };

    const updateContract = (updatedContract) => {
        setContracts((prevContracts) => {
            let ctrs = prevContracts.map((contract) =>
                contract._id === updatedContract._id ? updatedContract : contract
            )
            if (updatedContract?.parent) { ctrs = changeChildById(ctrs, updatedContract._id, updatedContract) }
            return ctrs
        }
        )
    };

    const changeChildById = (array, childId, newElement) => {
        // console.log('child changed', newElement)
        let item = array.filter(el => el._id === newElement.parent)
        if (item.length > 0 && item[0]?.children) {
            item[0].children = item[0]?.children.map(el => el._id.toString() === childId.toString() ? newElement : el)
        }
        return array;
    }


    // const changeChildById = (array, childId, newElement) => {
    //     let index
    //     array.forEach(obj => {
    //         if (obj.children && obj.children.length > 0) {
    //             obj.children.find((child, ind) => {
    //                 if (child._id === childId) { index = ind; return child }
    //             })
    //             if (index) { obj.children[index] = newElement }
    //         }
    //     })
    //     return array;
    // }


    const loadCustomers = () => {
        fetchCustomers()
    }

    const loadContracts = async () => {
        await fetchContracts()
        return contracts
    }

    const addContract = (newContract) => {
        setContracts((prevContracts) => {

            if (newContract.parent) {
                let ctrs = prevContracts.find(el => el._id === newContract.parent)
                if (ctrs && ctrs.children) {
                    ctrs.children = [...ctrs.children, newContract]
                }
            }
            return [...prevContracts, newContract]
        });

    };

    const deleteContract = (contractId) => {
        setContracts((prevContracts) => {
            let ctrs = prevContracts.filter((contract) => contract._id !== contractId)
            for (let c in ctrs) {
                if (ctrs[c]?.children) {
                    ctrs[c].children = ctrs[c]?.children?.filter((child) => child?._id !== contractId)
                }

            }
            return ctrs
        }

        );
    };

    const addBranch = (newBranch) => { }
    const updateBranch = (updatedBranch) => { }
    const deleteBranch = (branchId) => { }

    return {
        firstload, contracts, loadContracts, customers, addCustomer, updateCustomer, deleteCustomer, addContract, updateContract, deleteContract,
        branches, addBranch, updateBranch, deleteBranch, loadCustomers, machines, settings, changeSettings
    }
}