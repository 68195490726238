import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import Timeline, { TimelineHeaders, DateHeader, TimelineMarkers, TodayMarker } from "react-calendar-timeline/lib";
import moment from "moment";
import "react-calendar-timeline/lib/Timeline.css";
import { Select, Layout, DatePicker, Flex, Spin, Modal, Button, Grid } from "antd";
import { useHttp } from "../../hooks/http.hook";
import { AuthContext } from "../../context/AuthContext";
import "./timeline.css";
import dayjs from 'dayjs';
import 'moment/locale/ru'; // Import the desired locale
import { NavLink } from "react-router-dom";
import { TaskDescription } from "../Task/taskDescription";
import { PrintComponent } from "../Print/PrintComponent";

const { useBreakpoint } = Grid;
moment.locale('ru');
const { Content } = Layout;
const { Option } = Select;
const { RangePicker } = DatePicker;



const WorkerTimeline = () => {
    const [workers, setWorkers] = useState([]);
    const [selectedWorker, setSelectedWorker] = useState(null);
    const [items, setItems] = useState([]);
    const [groups, setGroups] = useState([]);
    const [description, setDescription] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    // Set initial visible time range to cover 32 days
    const initialStart = moment().add(-15, 'days').startOf('day').valueOf();
    const initialEnd = moment().add(15, 'days').endOf('day').valueOf();

    const [visibleTimeStart, setVisibleTimeStart] = useState(initialStart);
    const [visibleTimeEnd, setVisibleTimeEnd] = useState(initialEnd);

    const { request, loading } = useHttp();
    const { token } = useContext(AuthContext);

    const isMobile = useBreakpoint().xs;

    const nodeRef = useRef(null);


    useEffect(() => {
        // Fetch workers list
        const fetchWorkers = async () => {
            try {
                const response = await request('o/api/allbranches', 'GET', null, {
                    Authorization: `Bearer ${token}`
                });
                setWorkers(response); // Assume response is an array of worker objects
            } catch (error) {
                console.error("Error fetching workers:", error);
            }
        };

        fetchWorkers();
    }, [token, request]);

    const fetchTask = useCallback(async (id) => {
        try {
            const response = await request(`o/api/task/${id}`, 'GET', null, {
                Authorization: `Bearer ${token}`
            });
            // console.log(response);
            setDescription(response); // Assume response is an array of worker objects
        } catch (error) {
            console.error("Error fetching workers:", error);
        }
    }, [request, token])


    const handleView = useCallback((record) => {
        if (record) {
            fetchTask(record)
            showModal()
        }
    }, [fetchTask])

    const showModal = useCallback(() => setModalVisible(true), []);
    const handleOk = useCallback(() => setModalVisible(false), []);
    const handleCancel = useCallback(() => setModalVisible(false), []);

    const fetchTasks = useCallback(async (worker) => {
        // Fetch tasks for the selected worker
        setItems([])
        try {
            const response = await request(`o/api/task/worker/report`, 'POST',
                { workerId: worker },
                { Authorization: `Bearer ${token}` });
            const array = response.data; // Assume this is an array of tasks for the selected worker

            // Create group by unique worker IDs
            const workerGroups = array.reduce((acc, task) => {
                if (!acc.some(group => group.id === task.worker._id)) {
                    acc.push({
                        id: task.worker._id,
                        title: `${task.worker.lastname} ${task.worker.firstname} ${task.worker?.surname ? task.worker.surname[0] + "." : ""}`
                    });
                }
                return acc;
            }, []);

            workerGroups.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
            // Create timeline items
            let taskItems = [];
            let no = 0;
            workerGroups.forEach(wg => {
                let elems = array.filter(t => t.worker._id === wg.id); // Use filter instead of find
                if (elems && elems.length > 0) {
                    taskItems = [...taskItems, ...elems[0].tasks.map((task) => {
                        no++;
                        return {
                            id: no,
                            group: wg.id,
                            taskId: task._id,
                            title: task.taskTitle + ', ' + task.customer.name,
                            start_time: moment((task.startDate)),
                            end_time: moment((task.finishDate)),
                            className: getStatusClass(task.status),
                            canChangeGroup: false,
                        }
                    })];
                }
            });

            setGroups(workerGroups);
            setItems(taskItems);

        } catch (error) {
            console.error("Error fetching tasks:", error);
        }
    }, [token, request]);

    // Helper function to determine the CSS class based on task status
    const getStatusClass = (status) => {
        switch (status) {
            case "Назначено":
                return "planned-task";
            case "Выполнено":
                return "done-task";
            case "В работе":
                return "in-progress-task";
            default:
                return "planned-task";
        }
    };

    const handleWorkerChange = useCallback((value) => {
        setSelectedWorker(value);
        fetchTasks(value);
    }, [fetchTasks]);

    const handleDateRangeChange = useCallback((dates) => {
        if (!dates) {
            setVisibleTimeStart(initialStart);
            setVisibleTimeEnd(initialEnd);
        } else if (dates.length === 2) {
            setVisibleTimeStart(dates[0].valueOf());
            setVisibleTimeEnd(dates[1].valueOf());
        }
    }, [initialStart, initialEnd]);



    const minTime = moment().add(-6, 'months').valueOf()
    const maxTime = moment().add(6, 'months').valueOf()

    const handleTimeChange = (visibleTimeStart, visibleTimeEnd, updateScrollCanvas) => {
        if (visibleTimeStart < minTime && visibleTimeEnd > maxTime) {
            setVisibleTimeStart(minTime)
            setVisibleTimeEnd(maxTime)
            // updateScrollCanvas(minTime, maxTime)

        } else if (visibleTimeStart < minTime) {
            setVisibleTimeStart(minTime)
            setVisibleTimeEnd(minTime + (visibleTimeEnd - visibleTimeStart))
            // updateScrollCanvas(minTime, minTime + (visibleTimeEnd - visibleTimeStart))
        } else if (visibleTimeEnd > maxTime) {
            setVisibleTimeStart(maxTime - (visibleTimeEnd - visibleTimeStart))
            setVisibleTimeEnd(maxTime)
            // updateScrollCanvas(maxTime - (visibleTimeEnd - visibleTimeStart), maxTime)

        } else {

            setVisibleTimeStart(visibleTimeStart)
            setVisibleTimeEnd(visibleTimeEnd)
            updateScrollCanvas(visibleTimeStart, visibleTimeEnd)
        }
    }

    // const handleTimeChange = useCallback((visibleTimeStart, visibleTimeEnd, updateScrollCanvas) => {
    //     const minTime = dayjs().subtract(6, 'months').valueOf();
    //     const maxTime = dayjs().add(6, 'months').valueOf();

    //     if (visibleTimeStart < minTime && visibleTimeEnd > maxTime) {
    //         updateScrollCanvas(minTime, maxTime);
    //     } else if (visibleTimeStart < minTime) {
    //         updateScrollCanvas(minTime, minTime + (visibleTimeEnd - visibleTimeStart));
    //     } else if (visibleTimeEnd > maxTime) {
    //         updateScrollCanvas(maxTime - (visibleTimeEnd - visibleTimeStart), maxTime);
    //     } else {
    //         updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
    //     }
    // }, []);

    const handleItemMove = (item, el) => {
        console.log(item, new Date(el))
    }

    return (<>
        <Layout
        // style={{ minHeight: "100vh" }}
        >
            {/* <Header style={{
                background: "#fff", padding: "10px 20px", display: 'flex', alignItems: 'center',
                
            }}> */}

            <div ref={nodeRef}>
                <Flex wrap="wrap" gap="small" align='center' justify='space-between' >
                    <Select
                        placeholder="Выберите подразделение"
                        style={{ width: 200, marginRight: 20, width: '350px' }}
                        onChange={handleWorkerChange}
                        allowClear
                    >
                        {workers.map((worker, n) => (
                            <Option key={n} value={worker.value}>
                                {worker.value}
                            </Option>
                        ))}
                    </Select>
                    <RangePicker
                        format={'DD.MM.YYYY'}
                        label="Период"
                        // initialValue={[initialStart, initialEnd]}
                        value={[dayjs(visibleTimeStart), dayjs(visibleTimeEnd)]}
                        onChange={handleDateRangeChange}
                        popupClassName='rangeDatePicker'
                    />
                </Flex>
                {/* </Header> */}
                <Content style={{ overflowX: "hidden", paddingTop: '1rem' }}>
                    {items.length > 0 && selectedWorker ? <Timeline
                        groups={groups}
                        items={items}
                        visibleTimeStart={visibleTimeStart}
                        visibleTimeEnd={visibleTimeEnd}
                        onTimeChange={handleTimeChange} // Handle time changes when user scrolls/drag

                        canMove={true} // Enable horizontal scrolling
                        canResize={false} // Prevent resizing items
                        lineHeight={40} // Adjusts line height for readability

                        itemRenderer={({ item, itemContext, getItemProps, getResizeProps }) => {
                            return (
                                <div
                                    {...getItemProps({
                                        style: {
                                            background: item.className === "done-task"
                                                ? "green" : item.className === "planned-task"
                                                    ? "grey" : "lightblue",
                                            color: "white",
                                            borderRadius: "0px",
                                            paddingLeft: "4px",
                                            whiteSpace: 'nowrap', // Prevent text wrapping
                                            overflow: 'hidden', // Hide overflow
                                            textOverflow: 'ellipsis', // Ellipsis for overflow
                                            alignItems: 'center',
                                        },
                                    })}
                                    onDoubleClick={() => handleView(item?.taskId)}
                                    onTouchEndCapture={() => handleView(item?.taskId)}
                                >
                                    {item.title}
                                </div>
                            );
                        }}
                        zoomable={true} // Enable zooming

                        minZoom={60 * 60 * 24 * 1000 * 10} // Allow zooming in up to 10 days
                        maxZoom={60 * 60 * 24 * 1000 * 183} // Allow zooming out up to 1 year
                    >
                        <TimelineMarkers>
                            <TodayMarker style={{ backgroundColor: "red" }} />
                        </TimelineMarkers>
                        <TimelineHeaders>
                            <DateHeader unit="month" labelFormat="MMM YYYY" />
                            <DateHeader style={{ color: '#999999' }} unit="day" labelFormat="D" />
                        </TimelineHeaders>
                    </Timeline> :
                        loading ? <Spin />
                            : !selectedWorker ?
                                <div>Для вывода результатов отчета необходимо выбрать подразделение</div> :
                                <div style={{
                                    width: '100%',
                                    height: '50px',
                                    marginLeft: '20px',
                                    alignContent: 'center'
                                }}>
                                    <p>Нет данных</p>
                                </div>}
                </Content>
            </div>
        </Layout>
        {/* {items.length > 0 && <PrintComponent node={nodeRef} button={'Печать'} />} */}
        <Modal
            okText={"Закрыть"}
            centered
            width={isMobile ? '96vw' : '80vw'}
            afterClose={() => setDescription(null)}
            open={modalVisible} onOk={handleOk} onCancel={handleCancel}

            footer={(_, { OkBtn }) => (
                <Flex justify={description?.passport ? 'space-between' : 'right'} >
                    {description?.passport &&
                        (
                            <NavLink to={`/passport?id=${description.passport}`}>
                                <Button>Паспорт</Button>
                            </NavLink>)
                    }
                    <OkBtn />
                </Flex>
            )}
        >
            {loading ?
                <div style={{
                    height: '50vh',
                    width: isMobile ? '96vw' : '80vw'
                }}>
                    <p>Загрузка...</p> <Spin />
                </div> :
                description && <TaskDescription values={description} />}
        </Modal>



    </>
    );
};

export default WorkerTimeline;
