import React, { useContext, useEffect, useState } from 'react'
import { Drawer, Button, Modal, Grid, Flex, Descriptions, Divider, Table, Space, message } from 'antd'
import { PlusOutlined } from '@ant-design/icons';

import { useHttp } from '../hooks/http.hook'
import { AuthContext } from '../context/AuthContext'
import { CustomerForm } from '../components/Customer/CustomerForm'
import { CustomerList } from '../components/Customer/CustomerList'
import { DataContext } from '../context/DataContext'

const { useBreakpoint } = Grid;


export const CustomerPage = () => {
    const [customers, setCustomers] = useState([])
    const [editCustomer, setEditCustomer] = useState(null)
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [view, setView] = useState({})
    const [isModalOpen, setIsModalOpen] = useState(false)
    const isMobile = useBreakpoint().xs
    const { token, checkRole } = useContext(AuthContext)
    const { deleteCustomer, addCustomer, updateCustomer } = useContext(DataContext)
    const { request, loading } = useHttp()

    const handleCustomerForm = (values) => {
        // Create or update customer
        handleSaveCustomer(values)
        closeDrawer()
        setEditCustomer(null)
    }

    const handleSaveCustomer = async (values) => {
        // create or update customer
        if (editCustomer?._id) {
            // if update customer
            await updateExistedCustomer(editCustomer._id, values)
            // console.log('update customer') //fetch
            // setCustomers(customers.map(el => el._id === editCustomer._id ? editCustomer : el))
            // setEditCustomer(values)
        } else {
            // if create customer
            await createNewCustomer(values)
            // const newCustomer = { ...values, _id: new Date().getTime() }
            // console.log('create customer', newCustomer) //fetch
            // setCustomers([...customers, newCustomer])
            // setEditCustomer({ ...newCustomer, persons: [] })
        }
    }


    const createNewCustomer = async (newCustomer) => {
        try {
            let response = await request('/o/api/customer', 'POST', newCustomer, {
                Authorization: `Bearer ${token}`
            })
            setCustomers([...customers, response])
            setEditCustomer({ ...response, persons: [] })
            addCustomer(response)
        } catch (error) {

        }
    }

    const updateExistedCustomer = async (id, updatedCustomer) => {
        try {
            let response = await request(`/o/api/customer/${id}`, 'PUT', updatedCustomer, {
                Authorization: `Bearer ${token}`
            })
            setCustomers(customers.map(el => el._id === id ? response : el))
            setEditCustomer(response)
            updateCustomer(response)

        } catch (error) {

        }

    }


    const onEdit = (customer) => {
        setEditCustomer(customer)
        openDrawer()
    }

    const onDelete = async (ev) => {
        try {
            await request(`/o/api/customer/${ev._id}`, 'DELETE', null, {
                Authorization: `Bearer ${token}`
            })
            setCustomers(customers.filter(el => el._id !== ev._id))
            deleteCustomer(ev) //update context
        } catch (e) {
            message.error(e.message)
        }
    }

    const newCustomer = () => {
        setEditCustomer({})
        openDrawer()
    }

    const openDrawer = () => {
        setDrawerOpen(true)
    }

    const closeDrawer = () => {
        setEditCustomer({})
        setDrawerOpen(false)
    }

    const openModal = (values) => {
        setView(values)
        setIsModalOpen(true);
    }

    const closeModal = () => {
        setView(null);
        setIsModalOpen(false);
    }

    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const fetched = await request('/o/api/customer', 'GET', null, {
                    Authorization: `Bearer ${token}`
                })
                setCustomers(fetched)
            } catch (e) { }
        }
        fetchCustomers()
    }, [request, token])

    const onCreateUser = async (values) => {
        // отправка POST для создания пользователя
        // message при успешном завершении или при ошибке
        let name = values?.name?.split(' ')
        const registration = {
            role: 'customer',
            title: '',
            email: values?.email,
            lastname: name[0],
            firstname: name[1],
            surname: name[2] ?? '',
            customer: editCustomer?._id
        }

        let personToChange = editCustomer?.persons?.find(el => el.email === values?.email)



        try {
            const newUser = await request('o/api/users', 'POST', registration, {
                Authorization: `Bearer ${token}`
            })
            if (newUser) {
                // message.success(`Приглашение для завершения регистрации направлено на почту ${registration.email}`)
                //update PersonInfo
                // get customer values with _id and add to it account: {hasAccount: true, active: true}
                // update customer by request with PUT to /api/customer/:id

                if (personToChange) {
                    personToChange.account = { hasAccount: true, active: true }
                    const updatedCustomer = await request(`o/api/customer/${editCustomer._id}`, 'PUT', editCustomer, {
                        Authorization: `Bearer ${token}`
                    })
                    if (updatedCustomer) {
                        message.success(`Приглашение для завершения регистрации направлено на почту ${registration.email}`)
                    }
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const columns = [
        {
            title: 'ФИО и должность',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <>
                    <div>{text}</div>
                    <div style={{ color: 'grey', fontStyle: 'italic' }}>{record?.jobTitle}</div>
                </>
            )
        },
        // {
        //     title: 'Должность',
        //     dataIndex: 'jobTitle',
        //     key: 'jobTitle',
        // },
        {
            title: 'Телефон',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'email',
            dataIndex: 'email',
            key: 'email',
        },
    ]

    return (


        <>
            <Flex wrap="wrap" gap="small" align='center' justify='space-between' >
                <Space align='baseline' size={'middle'} >
                    <h2 style={{ marginBottom: 0, marginTop: 0 }}>Клиенты</h2>
                    {checkRole(['admin', 'legal']) && <Button type="default"
                        onClick={newCustomer}
                        shape={isMobile ? 'circle' : ''}
                        icon={<PlusOutlined />}
                        style={{ borderRadius: '20px' }}
                    >{isMobile ? '' : 'ДОБАВИТЬ'}</Button>}
                </Space>

            </Flex>
            {/* <div style={{ marginBottom: '16px' }}>
                <h2>Клиенты</h2>
                <Button type="primary" style={{ marginRight: '16px' }} onClick={openDrawer}>
                    + ДОБАВИТЬ
                </Button>
            </div> */}
            <Drawer
                title={editCustomer?._id ? editCustomer.name : 'Новый клиент'}
                width={700}
                placement="right"
                onClose={closeDrawer}
                open={drawerOpen}
                destroyOnClose={true}
                maskClosable={false}

            >
                <CustomerForm
                    // onClose={closeDrawer}
                    onFinishForm={handleCustomerForm}
                    // onSave={handleSaveCustomer}
                    edValues={editCustomer}
                    setEdValues={setEditCustomer}
                    onCreateUser={onCreateUser}
                />
            </Drawer>
            <CustomerList list={customers} loading={loading} onEdit={onEdit} onView={openModal} onDelete={onDelete} isMobile={isMobile} />
            <Modal
                title={view?.name}
                centered={true}
                width={isMobile ? '96vw' : '60vw'}
                open={isModalOpen} footer={null} onCancel={closeModal}
            >
                <Descriptions column={isMobile ? 1 : 2} size='small' >

                    <Descriptions.Item label="Наименование">{view?.form} {view?.name}</Descriptions.Item>
                    <Descriptions.Item label="ИНН">{view?.inn}</Descriptions.Item>
                    <Descriptions.Item label="Адрес">{view?.address}</Descriptions.Item>
                    <Divider />
                    <Descriptions.Item label="Email">{view?.email}</Descriptions.Item>
                    <Descriptions.Item label="Телефон">{view?.phone}</Descriptions.Item>

                </Descriptions>
                {view?.persons && view?.persons?.length > 0 && <>
                    <Divider orientation='left' style={{ fontWeight: 300 }}>Контакты</Divider>
                    <Table
                        dataSource={view?.persons}
                        columns={columns}
                        pagination={false}
                        size='small'
                        rowKey={'_id'}
                        scroll={{ x: 500 }} />
                </>}

            </Modal >
        </>

    )
}