export const data = (title) => {
    let values = {
        "Монтаж": [
            {
                label: 'Ок.Автомат',
                color: '#dcf0ff',
                text: 'Монтаж АСПТ выполнен в полном объеме замечаний нет, система опломбирована. Работает в автоматическом режиме.'
            },
            {
                label: 'Ок.Ручной',
                color: '#e5f4e5',
                text: 'Монтаж АСПТ выполнен в полном объеме, замечаний нет, система опломбирована. Переведена в ручной режим по просьбе заказчика.'
            },
        ],
        "Техническое обслуживание": [
            {
                label: 'Ок.Автомат',
                color: '#dcf0ff',
                text: "ТО выполнено АСПТ исправна, работает в автоматическом режиме. Замечаний со стороны заказчика нет."
            },
            {
                label: 'Ок.Ручной',
                color: '#e5f4e5',
                text: "ТО выполнено АСПТ исправна, переведена в ручной режим по просьбе заказчика. Замечаний со стороны заказчика нет."
            }

        ],
        "Ремонт": [
            {
                label: 'Ок.Автомат',
                color: '#dcf0ff',
                text: "Ремонт АСПТ согласован с заказчиком, выполнен в полном объеме, система исправна, работает в автоматическом режиме."
            },
            {
                label: 'Ок.Ручной',
                color: '#e5f4e5',
                text: "Ремонт АСПТ согласован с заказчиком, выполнен в полном объеме. Система исправна, переведена в ручной режим по просьбе заказчика. Замечаний со стороны заказчика нет."
            }

        ],
        "Перезарядка": [
            {
                label: 'Ок.Автомат',
                color: '#dcf0ff',
                text: "АСПТ ЭПОТОС перезаряжена, исправна, работает в автоматическом режиме. Замечаний со стороны заказчика нет."
            },
            {
                label: 'Ок.Ручной',
                color: '#e5f4e5',
                text: "АСПТ ЭПОТОС перезаряжена, исправна, переведена в ручной режим по просьбе заказчика. Замечаний со стороны заказчика нет."
            }

        ],
        "Диагностика": [
            {
                label: 'Ок.Автомат',
                color: '#dcf0ff',
                text: "АСПТ ЭПОТОС исправна работает в автоматическом режиме. Замечаний у заказчика нет."
            },
            {
                label: 'Ок.Ручной',
                color: '#e5f4e5',
                text: "АСПТ ЭПОТОС исправна, переведена в ручной режим по просьбе заказчика. Замечаний со стороны заказчика нет."
            }

        ],
        "Дефектовка": [
            {
                label: 'Требуется перезарадка',
                color: '#dcf0ff',
                text: "АСПТ ЭПОТОС неисправна, требуется перезарядка."
            },
            {
                label: 'Требуется ремонт',
                color: '#e5f4e5',
                text: "АСПТ ЭПОТОС неисправна, требуется проведение ремонта."
            }

        ]



    }
    return values[title] ?? []
}