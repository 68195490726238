import React, { useState, useEffect, useContext } from 'react';
import { Button, Drawer, Space, message, Modal, DatePicker, Flex, Grid, Spin } from 'antd';
import { TaskForm } from '../components/Task/TaskForm';
import { TaskList } from '../components/Task/TaskList_prev';
import { NavLink } from 'react-router-dom';
import { useHttp } from '../hooks/http.hook'
import axios from 'axios'
import { AuthContext } from '../context/AuthContext';
import { DataContext } from '../context/DataContext';
import dayjs from 'dayjs'
import { TaskDescription } from '../components/Task/taskDescription';
import { PlusOutlined } from '@ant-design/icons';
import { operationHoursCalculator } from '../components/Actual/operationHours';
import Help from '../components/Help/Help';
import { BulkTaskForm } from '../components/Task/BulkTaskForm';


const { useBreakpoint } = Grid;

const headTitle = {
  done: "Выполнено",
  plan: "План",
  submitted: "Выдано",
  nodocs: "Нет фото",
}

let now = new Date()

// let startPeriod = [
//   dayjs(new Date(now - 30 * 1000 * 60 * 60 * 24)),
//   dayjs(new Date(now.getFullYear(), now.getMonth() + 1, 0))
// ]

let startPeriod

export const TaskPage = ({ field, rl }) => {

  const [visible, setVisible] = useState(false);
  const [bulkOpen, setBulkOpen] = useState(false)
  const [tasks, setTasks] = useState([]);
  const [periodTasks, setPeriodTasks] = useState([])
  const [taskList, setTaskList] = useState([]);
  // const [customer, setCustomer] = useState([]);
  const [filtering, setFiltering] = useState(false);
  const [machines, setMachines] = useState([]);
  const [fileList, setFileList] = useState([])
  const [act, setAct] = useState([])
  const [workers, setWorkers] = useState([]);
  const [equipments, setEquipments] = useState([])
  const [formInitial, setFormInitial] = useState({})
  const [percent, setPercent] = useState(0)
  const [description, setDescription] = useState({})
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [timeRange, setTimeRange] = useState(startPeriod)
  const { request, loading } = useHttp()
  const { token, userId, checkRole } = useContext(AuthContext)


  const { contracts, customers, settings } = useContext(DataContext)

  const isMobile = useBreakpoint().xs;
  // const period = settings.period

  useEffect(() => {

    filterTaskByStatus(taskList);

  }, [field, taskList]);


  useEffect(() => {
    handlePeriodFilter(timeRange)
  }, [tasks, taskList])

  useEffect(() => {
    handlePeriodFilter(timeRange)
  }, [timeRange])


  useEffect(() => {
    fetchTasks();
    // fetchCustomers();
    fetchMachines();
    fetchWorkers();
    fetchEquipments();

    setTimeRange(startPeriod) // удален первоначальный фильтр по просьбе Дмитрия А. 07.09.2024
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);







  // const filterTaskByStatus = (updTaskList) => {
  //   let filteredTasks
  //   if (field === 'plan') {
  //     filteredTasks = updTaskList.filter(task => task.status === 'Запланировано')
  //   }
  //   else if (field === 'submitted') {
  //     filteredTasks = updTaskList.filter(task => task.status === 'Выдано' || task.status === 'В работе')
  //   } else if (field === 'done') {
  //     filteredTasks = updTaskList.filter(task => task.status === 'Выполнено')
  //   } else if (field === 'nodocs') {
  //     filteredTasks = updTaskList.filter(task => (task.pictures.length === 0))
  //   } else {
  //     filteredTasks = [...updTaskList]
  //   }
  //   setTasks(filteredTasks)
  //   // setPeriodTasks(filteredTasks) //setState for component
  //   // handlePeriodFilter(timeRange)
  //   return filteredTasks

  // }

  const filterTaskByStatus = (updTaskList) => {
    setFiltering(true);  // Set filtering to true when filtering starts
    let filteredTasks;
    if (field === 'plan') {
      filteredTasks = updTaskList.filter(task => task?.status === 'Запланировано');
    } else if (field === 'submitted') {
      filteredTasks = updTaskList.filter(task => task?.status === 'Выдано' || task?.status === 'В работе');
    } else if (field === 'done') {
      filteredTasks = updTaskList.filter(task => task?.status === 'Выполнено');
    } else if (field === 'nodocs') {
      filteredTasks = updTaskList.filter(task => task?.pictures?.length === 0);
    } else {
      filteredTasks = [...updTaskList];
    }
    setTasks(filteredTasks);
    setFiltering(false);  // Set filtering to false once filtering is done
    return filteredTasks;
  };

  const emptyFields = {
    taskTitle: '',
    startDate: '',
    finishDate: '',
    taskTransport: { travelDateTime: dayjs(new Date()) },
    priority: 'Средний',
    paymentForm: 'Платно',
    worker: [],
    machine: '',
    branch: '',
    taskDescription: '',
    status: ' Запланировано',
  }

  const fetchTasks = async () => {
    let url = (rl === 'user' && checkRole(['user'])) ? `o/api/task/user/${userId}` : 'o/api/task'
    try {
      const response = await request(url, 'GET', null, {
        Authorization: `Bearer ${token}`
      });
      setTaskList(response);
    } catch (error) {
      console.error('Нет связи с сервером', error);
    }
  };

  const fetchEquipments = async () => {
    try {
      const response = await request('o/api/equipment', 'GET', null, {
        Authorization: `Bearer ${token}`
      });
      setEquipments(response);
    } catch (error) {
      console.error('Нет связи с сервером', error);
    }
  };

  const fetchWorkers = async () => {
    try {
      const response = await request('o/api/workers', 'GET', null, {
        Authorization: `Bearer ${token}`
      });
      setWorkers(response);
    } catch (error) {
      console.error('Нет связи с сервером', error);
    }
  };

  const fetchMachines = async () => {
    try {
      const response = await request('o/api/machines', 'GET', null, {
        Authorization: `Bearer ${token}`
      });
      setMachines(response);
    } catch (error) {
      console.error('Нет связи с сервером', error);
    }
  };

  const createPassport = async (values) => {
    let deliveryDate = dayjs(values.documentDate ?? values.finishDate);
    const newPassport = {
      installation: values._id,
      deliveryDate,
      equipment: values.equipment,
      maintenance: [],
      nextMaintenanceDate: new Date(new Date(deliveryDate).getTime() + 183 * (24 * 60 * 60 * 1000)),
      warrantyFinishDate: (new Date((new Date(deliveryDate).setFullYear(new Date(deliveryDate).getFullYear() + 1)) - 1000 * 60 * 60 * 24)).setHours(0, 0, 0, 0),
      warranty: '12_2000',
      comment: `Создано из задания монтажа на основании Акта ${values.documentNumber} от ${new Date(deliveryDate).toLocaleDateString('ru-RU')}`,
      contract: values?.contract?._id,
      subContract: null,
    }
    try {
      const response = await request('o/api/passport', 'POST', newPassport,
        { 'Authorization': `Bearer ${token}` });
      if (response) {
        message.success('Паспорт успешно создан');
      }
    } catch (error) {
      console.error(error);
    }
  }


  const createFormData = (values) => {
    const { pictures, worker, equipment, taskTransport, acts, ...otherFormData } = values
    const formDataToSend = new FormData();
    // console.log('values', values)
    Object.entries(otherFormData).forEach(([key, value]) => {
      formDataToSend.append(key, value);
    });

    pictures && pictures.forEach((el) => {
      formDataToSend.append('pictures', JSON.stringify(el))
    })

    taskTransport && formDataToSend.append('taskTransport', JSON.stringify(taskTransport))
    worker && worker.forEach((el) => {
      formDataToSend.append('worker', el)
    })

    equipment && equipment.forEach((el) => {
      formDataToSend.append('equipment', JSON.stringify(el))
    })

    fileList && fileList.forEach((file) => {
      formDataToSend.append('newfiles', file)
    })

    // console.log('taskpage act', act)
    // acts && acts.forEach((file) => {
    act && act.forEach((file) => {
      formDataToSend.append('acts', file.originFileObj) //.originFileObj
    })


    // console.log(formDataToSend.getAll('worker'))
    return formDataToSend

  }

  const handleAddTask = async (values) => {
    try {
      let formDataToSend = createFormData(values)
      const response = await
        fetch(`o/api/task`, {
          method: 'POST',
          body: formDataToSend,
          headers: { Authorization: `Bearer ${token}` }
        });

      if (!response.ok) {
        throw new Error('Failed to update task');
      }
      const jsonResponse = await response.json();
      const updatedTasks = [...taskList, jsonResponse]
      setTaskList(updatedTasks);
      // filterTaskByStatus(updatedTasks)
      message.success('Задание добавлено успешно!')
    } catch (error) {
      console.error('Error adding task:', error);
    }
  };
  const onUploadProgress = (event) => {
    const percentage = Math.round((100 * event.loaded) / event.total);
    setPercent(percentage)
    if (percentage === 100) {
      onClose()
      setPercent(0)
    }
  };


  const updateMaintenance = (updatedTask) => {

    let lastMaintenanceDate, delivery, period, data

    console.log('updateMaintenace', updatedTask)
    if (updatedTask?.documentNumber) {
      lastMaintenanceDate = updatedTask.finishDate
      //find passport with machine

    }



    // if (updatedTask?.documentNumber) {
    //   const nextMaintenance = operationHoursCalculator(data, delivery, lastMaintenanceDate, period)
    // }
  }

  const handleUpdateTask = async (id, updatedTask) => {
    try {

      // в случае если акт заполнен
      // вызвать функцию operationHoursCalculator(data, delivery, lastMaintenanceDate, period)
      // получим { averageHoursPerDay, nextMaintenanceDate }
      // if (updatedTask?.documentNumber) {
      //   const nextMaintenance = operationHoursCalculator(data, delivery, lastMaintenanceDate, period)
      // }


      let formDataToSend = createFormData(updatedTask)
      const response = await axios.put(
        `/o/api/task/${id}`,
        formDataToSend,
        {
          headers: {
            'Content-Type': "multipart/form-data",
            'Authorization': `Bearer ${token}`
          }, proxy: {
            port: 5000,
          },
          onUploadProgress
        },
      )
      if (!response.status === 200) {
        throw new Error('Failed to update task');
      }


      const jsonResponse = response.data
      const updatedTasks = taskList.map((task) => (task._id === id ? jsonResponse : task));
      setTaskList(updatedTasks)
      //update machine

      const updatedMachines = machines.map(m => (
        m._id === jsonResponse?.machine?._id ? { ...m, ...jsonResponse?.machine } : m
      ))
      setMachines(updatedMachines)

      // filterTaskByStatus(updatedTasks)
      message.success('Задание обновлено успешно!')
      //update maintenanceDate

      // updateMaintenance(jsonResponse)
      //create passport
      if (!updatedTask.passport && updatedTask.delivery) {
        createPassport(updatedTask)
      }
    } catch (error) {
      console.error('Error updating task:', error);
      if (error?.response?.status === 413) {
        message.error('Размер одного файла не может превышать 30 Мб');
      } else {
        message.error('Ошибка обновления данных');
      }

    }
  }
  const onShow = () => {
    setFormInitial(emptyFields)
    setVisible(true)
  }

  const onClose = () => {
    setFileList([])
    setVisible(false);
  };

  const onBulkClose = () => {
    setBulkOpen(false)
  }


  const submitForm = async (formValues) => {
    // console.log('formvalues', formValues)
    if (formValues?._id) {
      await handleUpdateTask(formValues._id, formValues)
    } else {
      await handleAddTask(formValues)
    }
    onClose()
  }

  const submitGroupForm = async (formValues) => {
    try {
      // console.log(formValues)
      let response = await request('o/api/taskgroup/create', 'POST', formValues, {
        Authorization: `Bearer ${token}`
      })
      const updatedTasks = [...taskList, ...response?.data]
      setTaskList(updatedTasks);
      // filterTaskByStatus(updatedTasks)
      message.success(`Задания (${response?.data.length}) добавлены успешно!`)
      onBulkClose()
    } catch (error) {
      console.error('Error updating task:', error);
    }
  }


  const handleEditTask = (task) => {
    let transformed = {
      ...task,
      startDateFinishDate: [dayjs(task?.startDate), dayjs(task?.finishDate)],
      customer: task?.customer._id,//{ label: task.customer.name, value: task.customer._id },
      workers: task?.worker?.map((person) => person?._id ?? person),
      machine: task?.machine?._id, //task?.machine?._id, //Changed 21/04/2024
      contract: task?.contract?._id,
      garageNumber: task?.machine?.garageNumber,
      serialNumber: task?.machine?.serialNumber, //new
      equipment: task.equipment.map((el, n) => ({ ...el, key: Number(new Date()) + 2 * n })),
      taskTransport: {
        ...task?.taskTransport,
        travelDateTime: task?.taskTransport?.travelDateTime ? dayjs(task?.taskTransport?.travelDateTime) : dayjs(new Date())
      },
    }
    if (transformed?.documentDate) { transformed.documentDate = dayjs(transformed?.documentDate) }
    setFormInitial(transformed)
    setVisible(true)
  }

  // const handlePeriodFilter = (el) => {
  //   setTimeRange(el)
  //   if (el) {
  //     let period = {
  //       start: (new Date(el[0] ?? new Date('1970-01-01'))).setHours(0, 0, 0, 0),
  //       finish: (new Date(el[1])).setHours(23, 59, 59, 999) ?? new Date()
  //     }

  //     let tasksWithPeriodFilter = tasks.filter((ts) => (new Date(ts.startDate) >= period.start
  //       && new Date(ts.startDate) <= period.finish))
  //     setPeriodTasks(tasksWithPeriodFilter)
  //   } else {
  //     setPeriodTasks(tasks)
  //   }
  // }

  const handlePeriodFilter = (el) => {
    setFiltering(true);  // Set filtering to true when filtering starts
    setTimeRange(el);
    if (el) {
      let period = {
        start: (new Date(el[0] ?? new Date('1970-01-01'))).setHours(0, 0, 0, 0),
        finish: (new Date(el[1])).setHours(23, 59, 59, 999) ?? new Date()
      };

      let tasksWithPeriodFilter = tasks.filter((ts) => new Date(ts.startDate) >= period.start && new Date(ts.startDate) <= period.finish);
      setPeriodTasks(tasksWithPeriodFilter);
    } else {
      setPeriodTasks(tasks);
    }
    setFiltering(false);  // Set filtering to false once filtering is done
  };

  const handleDeleteTask = async (id) => {
    try {
      await request(`o/api/task/${id}`, 'DELETE', null, {
        Authorization: `Bearer ${token}`
      });
      setTaskList(tasks.filter((task) => task._id !== id));
      // filterTaskByStatus(tasks.filter((task) => task._id !== id))
      message.success('Задание удалено');
    } catch (error) {
      console.error('Error deleting task:', error);
      message.error('Ошибка удаления задания');
    }
  }

  const onBulkClik = () => {
    setBulkOpen(true)
  }

  const handleView = (record) => {
    setDescription(record)
    showModal()
  }
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Flex wrap="wrap" gap="small" align='center' justify='space-between' >
        <Space align='baseline' size={'middle'} >
          <h2 style={{ marginBottom: 0, marginTop: 0 }}>Управление заданиями <span style={{ color: 'gray', fontWeight: '300' }}>{headTitle[field]}</span></h2>
          {field === "plan" && <Button
            type={settings?.contractTypes ? "primary" : 'default'}
            disabled={!settings?.contractTypes}
            onClick={onShow}
            shape={isMobile ? 'circle' : ''}
            icon={<PlusOutlined />}
            style={{ borderRadius: '20px' }}
          >{isMobile ? '' : 'ДОБАВИТЬ'}</Button>}
          {field === "plan" && !isMobile && <Button
            type={settings?.contractTypes ? "primary" : 'default'}
            disabled={!settings?.contractTypes}
            shape={isMobile ? 'circle' : ''}
            icon={<PlusOutlined />}
            style={{ borderRadius: '20px' }}
            onClick={onBulkClik}>
            Групповое задание
          </Button>
          }
        </Space>
        <Space align='baseline' size={'small'} justify={'end'}>
          <p>Период: </p>
          <DatePicker.RangePicker
            format={'DD.MM.YYYY'}
            popupStyle={{ width: '290px' }}
            onChange={handlePeriodFilter}
            popupClassName='rangeDatePicker'

            value={timeRange}
          // style={{ marginBottom: '1rem' }}
          />
          {/* <Button type="primary"
            icon={<SearchOutlined />}
            shape={isMobile ? 'circle' : ''}
            onClick={() => { }}
          >{isMobile ? '' : 'Показать'}</Button> */}
        </Space>
      </Flex>
      <Drawer
        title={formInitial?._id ? "Редактирование" : "Новое задание"}
        width={800}
        open={visible}
        onClose={onClose}
        destroyOnClose={true}
        extra={
          <Space>
            {`Статус: ${formInitial.status}`}
          </Space>
        }
      >
        <TaskForm
          onClose={onClose}
          onSubmit={submitForm}
          workers={workers}
          // customers={customer}
          machines={machines}
          setMachines={setMachines}
          equipments={equipments}
          initialValues={formInitial}
          fileList={fileList}
          setFileList={setFileList}
          act={act}
          setAct={setAct}
          percent={percent}
          isMobile={isMobile}
        />
      </Drawer>

      <TaskList tasks={periodTasks} onEdit={handleEditTask}
        onDelete={handleDeleteTask} onView={handleView}
        loading={loading || filtering}
        sMobile={isMobile} />

      <Drawer
        title={"Новое групповое задание"}
        width={800}
        open={bulkOpen}
        onClose={() => setBulkOpen(false)}
        destroyOnClose={true}
        maskClosable={false}
      >
        <BulkTaskForm
          machines={machines}
          workers={workers}
          isMobile={isMobile}
          setMachines={setMachines}
          onFinish={submitGroupForm}
        />
      </Drawer >

      <Modal
        okText={"Закрыть"}
        centered
        width={isMobile ? '96vw' : '80vw'}
        afterClose={() => setDescription(null)}
        open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
        // footer={(_, { OkBtn }) => (<OkBtn />)}
        footer={(_, { OkBtn }) => (
          <Flex justify={description.passport ? 'space-between' : 'right'} >
            {description.passport &&
              (
                <NavLink to={`/passport?id=${description.passport}`}>
                  {/* <NavLink to={`/passport/${description.passport}`}> */}

                  <Button>Паспорт</Button>
                </NavLink>)

            }
            <OkBtn />
          </Flex>
        )}
      >
        <TaskDescription values={description} />
      </Modal>
      {/* <Help pageId={'/task'} /> */}
    </ >
  );
};

